:root {
  --primary-color: #f3921e;
  --secondary-color: #223b7c;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 3rem;
  justify-content: space-between;
}

.navbar-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-toggler {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--primary-color);
  border: 0;
  outline: 0;
  display: none;
  justify-content: center;
  align-items: center;
}

.navbar-content {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.navbar .logo {
  width: 200px;
  margin-right: auto;
}

.nav-links {
  color: var(--secondary-color);
  cursor: pointer;
}

.navbar .active {
  color: var(--primary-color);
}

.partner-btn {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 8px 16px;
  gap: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.partner-btn span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.partner-btn img {
  width: 6px;
  height: auto;
}

.search-btn {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.main-hero-section {
  background: url("images/BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.main-hero-conainer-first {
  width: 60%;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.main-hero-conainer-second {
  width: 30%;
  padding: 1rem 0;
}

.main-hero-conainer-second img {
  max-width: 100%;
  height: auto;
}

.main-hero-heading {
  color: #ffffff;
  font-size: 40px;
  font-weight: 500;
}

.learn-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  color: #ffffff;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  background: none;
  cursor: pointer;
}

.learn-btn:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.main-hero-section .learn-btn {
  margin-right: auto;
}

.description-div {
  display: flex;
  align-items: center;
  padding: 3rem;
  gap: 1.5rem;
}

.description-text {
  width: 60%;
  line-height: 1.5;
  color: var(--secondary-color);
  font-family: "ABeeZee", sans-serif;
  font-size: 19px;
}

.description-text span {
  color: var(--primary-color);
}

.descriptions-cards {
  width: 40%;
  display: flex;
  gap: 1rem;
}

.description-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
  padding: 1.5rem 1rem;
  background: #f9f9f9;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
}

.description-card img {
  width: 100%;
  max-width: 150px;
  height: auto;
}

.provide_heading {
  font-family: "Actor", sans-serif;
  color: var(--secondary-color);
  font-size: 33px;
  margin-bottom: 2rem;
}

.provide-div {
  display: flex;
  align-items: start;
  padding: 3rem;
  gap: 3rem;
}

.provide-text {
  width: 60%;
  font-family: "ABeeZee", sans-serif;
  color: var(--secondary-color);
  font-size: 20px;
  line-height: 1.5;
}

.provide-div .provide-text .learn-btn {
  display: flex;
  margin-top: 2rem;
  color: var(--secondary-color);
}
.provide-div .provide-text .learn-btn:hover {
  color: #fff;
}

.provider-cards {
  width: 40%;
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
}

.provide-text span {
  color: var(--primary-color);
}

.provider-card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 12px;
}

.affeliate-section {
  background: var(--primary-color);
  padding: 5rem 3rem;
}

.affeliate-heading {
  color: white;
  font-family: "Actor", sans-serif;
  font-weight: 400;
  font-size: 34px;
  margin-bottom: 1.5rem;
}

.affeliate-text {
  color: white;
  font-family: "Abel", sans-serif;
  margin-bottom: 3rem;
  font-size: 21px;
}

.affeliate-cards {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 16px;
}

.affeliate-card img {
  width: 120px;
}

.affeliate-card {
  background: white;
  border-radius: 8px;
  padding: 3rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-section {
  display: flex;
  gap: 3rem;
  padding: 3rem;
}

.contact-us-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.5rem;
}

.contact-heading {
  font-weight: 600;
  font-size: 30px;
}

.contact-detail-section {
  display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center;
}

.contact-detail-sub {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.contact-detail-sub span {
  color: #707070;
  font-size: 15px;
  font-family: "Abel", sans-serif;
}

.contact-detail-sub div {
  color: #414042;
  font-family: "Abel", sans-serif;
}

.contact-detail-section img {
  height: 24px;
  width: 24px;
}

.contact-us-right {
  width: 50%;
}

.inputs-container-group {
  display: flex;
  gap: 1.5rem;
  width: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.input-container1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.input-container label {
  color: #0f0f0f;
  font-family: "Actor", sans-serif;
  font-size: 20px;
}

.input-container1 label {
  color: #0f0f0f;
  font-family: "Actor", sans-serif;
  font-size: 20px;
}

.input-container input {
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 10px;
  outline: none;
}

.input-container1 input {
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 10px;
  outline: none;
}

.input-container1 textarea {
  background: #f8f8f8;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  padding: 10px;
  outline: none;
}

.input-container input::placeholder {
  font-size: 14px;
}

.input-container1 textarea::placeholder {
  font-size: 14px;
}

.input-container1 input::placeholder {
  font-size: 14px;
}

.sent-btn {
  background: var(--primary-color);
  color: white;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-family: "Actor", sans-serif;
  font-weight: 400;
  font-size: 20px;
  border: none;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 3rem;
}

.footer {
  padding: 3rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.footer-logp-section img {
  width: 256px;
}

.footer-main-link-section {
  display: flex;
  gap: 5rem;
}

.footer-link-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.footer-bold {
  color: #000000;
  font-family: "Actor", sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.footer-link {
  color: #000000;
  font-family: "Abel", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.copyright-section {
  color: #000000;
  padding: 1.5rem 3rem;
  font-size: 14px;
}

.trusted-by {
  padding: 3rem;
}

.trust-heading {
  color: #223b7c;
  font-family: "Actor", sans-serif;
  font-size: 33px;
  margin-bottom: 2rem;
}

.slider-container {
  padding: 0 3rem;
}

.slider-item img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
}

.dropdown-icon {
  height: 21px;
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 2px;
}

@media (max-width: 1280px) {
  .main-hero-heading {
    font-size: 30px;
  }
  .navbar {
    padding: 0.5rem 1.5rem;
  }
  .navbar-content {
    gap: 1.5rem;
  }
  .provide-text {
    width: 50%;
    font-size: 16px;
  }
  .provider-cards {
    width: 50%;
  }
  .description-text {
    width: 50%;
    font-size: 16px;
  }
  .descriptions-cards {
    width: 50%;
  }
  .provide_heading {
    margin-bottom: 1rem;
  }
  .provide-div .provide-text .learn-btn {
    margin-top: 1.5rem;
  }
}

@media (max-width: 1100px) {
  .navbar {
    flex-direction: column;
    align-items: start;
  }
  .navbar-header {
    width: 100%;
  }
  .navbar-toggler {
    display: flex;
  }
  .navbar-content {
    display: none;
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
  .navbar.open .navbar-content {
    display: flex;
  }
  .contact-heading {
    margin: 0 auto;
    text-align: center;
  }
  .contact-us-section {
    flex-wrap: wrap;
  }
  .contact-us-left,
  .contact-us-right {
    width: 100%;
  }
  .trust-heading {
    font-size: 30px;
  }
  .provide_heading {
    font-size: 30px;
  }
  .affeliate-heading {
    font-size: 30px;
  }
  .affeliate-text {
    font-size: 18px;
  }
  .affeliate-cards {
    grid-template-columns: auto auto auto auto;
  }
}

@media (max-width: 991px) {
  .description-div {
    flex-wrap: wrap;
  }
  .description-text,
  .descriptions-cards {
    width: 100%;
  }
  .provide-div {
    flex-wrap: wrap;
  }
  .provide-text,
  .provider-cards {
    width: 100%;
  }
  .main-hero-heading {
    font-size: 25px;
  }
  .provide_heading,
  .affeliate-heading,
  .contact-heading,
  .trust-heading {
    font-size: 27px;
  }
  .footer-main-link-section {
    gap: 1.5rem;
  }
  .footer-logp-section img {
    width: 200px;
  }
  .affeliate-cards {
    grid-template-columns: auto auto auto;
  }
  .main-hero-conainer-first {
    gap: 1.75rem;
  }
  .input-container label,
  .input-container1 label {
    font-size: 16px;
  }
  .affeliate-text {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .affeliate-cards {
    grid-template-columns: auto auto;
  }
  .main-hero-section {
    flex-wrap: wrap;
    padding: 1.5rem;
  }
  .main-hero-conainer-first {
    width: 100%;
    padding: 0;
    justify-content: center;
  }
  .main-hero-section .learn-btn {
    margin: 0 auto;
  }
  .main-hero-conainer-second {
    width: 100%;
    order: -1;
    padding: 0;
  }
  .main-hero-conainer-second img {
    max-width: 300px;
    display: block;
    margin: 0 auto;
  }
  .main-hero-heading {
    text-align: center;
  }
  .description-div {
    padding: 2rem 1.5rem;
  }
  .trusted-by {
    padding: 1.5rem;
  }
  .footer {
    padding: 1.5rem;
    padding-top: 0;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }
  .footer-main-link-section {
    width: 100%;
    justify-content: space-between;
  }
}

@media (max-width: 575px) {
  .main-hero-heading {
    font-size: 21px;
  }
  .main-hero-conainer-first {
    gap: 1.25rem;
  }
  .main-hero-section {
    padding: 1rem 1rem 1.5rem 1rem;
  }
  .description-div {
    padding: 1rem;
  }
  .trusted-by {
    padding: 1rem;
  }
  .slider-container {
    padding: 0 1.5rem;
  }
  .provide-div {
    padding: 1rem 1rem 1.5rem 1rem;
    gap: 1.5rem;
  }
  .provide-div .provide-text .learn-btn {
    margin-top: 1rem;
  }
  .provider-cards {
    grid-template-columns: auto;
  }
  .affeliate-section {
    padding: 2rem 1rem;
  }
  .affeliate-heading {
    margin-bottom: 1rem;
  }
  .affeliate-text {
    margin-bottom: 1.5rem;
  }
  .contact-us-section {
    padding: 1.5rem 1rem;
    gap: 2rem;
  }
  .contact-form {
    padding: 1.5rem;
  }
  .inputs-container-group {
    flex-wrap: wrap;
  }
  .footer-main-link-section {
    flex-direction: column;
  }
  .footer-link-section {
    gap: 1rem;
  }
  .copyright-section {
    padding: 1rem;
    text-align: center;
  }
}
